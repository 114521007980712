<template>
  <v-container fill-height>
    <v-layout row wrap>
      <v-flex>
        <v-avatar
          tile
          width="150px"
          height="150px"
          class="profile rounded"
          @click="openCropper"
        >
          <image-cropper v-model="showCropperModal" @change="onChangeImage" />

          <div class="logo-container" v-if="imageSource || iconImage">
            <v-img
              :src="imageSource && !imageChanged ? imageSource : iconImage"
            ></v-img>
          </div>
          <div class="logo-container" v-else>
            <v-icon class="mt-12 mb-1" size="30">{{ icons.camera }}</v-icon>
            <div class="grey--text text--darken-2 caption">Upload Image</div>
          </div>
        </v-avatar>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mdiCamera } from '@mdi/js'
import ImageCropper from '@/components/modals/ImageCropper'

export default {
  components: {
    ImageCropper,
  },

  props: {
    imageSource: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showCropperModal: false,
      imageChanged: false,
      iconImage: null,
      icons: {
        camera: mdiCamera,
      },
    }
  },

  methods: {
    openCropper() {
      if (!this.disabled) {
        this.showCropperModal = true
      }
    },

    onChangeImage(blob) {
      let img = URL.createObjectURL(blob)
      this.imageChanged = true
      this.iconImage = img
      this.$emit('change', blob)
    },
  },
}
</script>

<style lang="scss" scoped>
.logo-container {
  background-color: #f0f3f4;
  width: 150px;
  height: 150px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}
</style>
