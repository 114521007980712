<template>
  <div class="py-8">
    <v-app-bar flat color="transparent">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <router-link class="text-decoration--none" :to="{ name: 'missions' }">
          <div class="div-missions">Missions</div>
        </router-link>
        <v-icon>{{ icons.arrowRight }}</v-icon>
        {{ mission ? mission.title : '' }}
      </v-toolbar-title>
    </v-app-bar>

    <MissionForm />
  </div>
</template>

<script>
import { mdiChevronRight } from '@mdi/js'
import { mapState } from 'vuex'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import MissionForm from './components/MissionForm.vue'

export default {
  name: 'UserDetails',

  components: {
    AppBarNavIcon,
    MissionForm,
  },

  data() {
    return {
      icons: {
        arrowRight: mdiChevronRight,
      },
    }
  },

  computed: {
    ...mapState({
      mission: (state) => state.mission.missionDetails,
    }),
  },
}
</script>

<style scoped>
.div-missions {
  color: black;
}
</style>
