<template>
  <div class="mb-12">
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <!-- end appbar nav icon -->
      <v-toolbar-title class="headline font-weight-semibold"
        >Missions ({{ missions.length }})</v-toolbar-title
      >
      <!-- end toolbar title -->
    </v-app-bar>
    <!-- end app bar -->
    <div class="pa-4 mb-12">
      <v-row v-show="!isLoading">
        <template>
          <v-col
            xs="12"
            sm="12"
            md="6"
            lg="4"
            v-for="mission in missions"
            :key="mission.id"
          >
            <v-card raised height="150px">
              <v-card-title class="headline">
                <router-link
                  tag="span"
                  class="mission-title"
                  :to="{
                    name: 'mission.details',
                    params: { id: mission.id },
                  }"
                  >{{ mission.name }}
                </router-link>
              </v-card-title>
              <v-card-subtitle class="mission-desc">
                {{ mission.description }}
              </v-card-subtitle>
            </v-card>
          </v-col>
        </template>
      </v-row>
    </div>

    <v-btn
      color="primary"
      id="add-mission-button"
      large
      dark
      rounded
      elevation="16"
      @click="
        $router.push({
          name: 'mission.new',
        })
      "
    >
      <v-icon>
        {{ icons.plus }}
      </v-icon>
      <span class="ml-2">Add Mission</span>
    </v-btn>
  </div>
</template>

<script>
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import { mapActions, mapMutations, mapState } from 'vuex'
import { mdiPlusCircleOutline } from '@mdi/js'

export default {
  name: 'MissionPage',

  mixins: [ControlsMixin],

  components: {
    AppBarNavIcon,
  },

  data() {
    return {
      isLoading: false,
      icons: {
        plus: mdiPlusCircleOutline,
      },
    }
  },

  mounted() {
    this.clearMissions()
    this.fetchMissions()
  },

  computed: {
    ...mapState({
      missions: (state) => state.mission.list,
    }),
  },

  methods: {
    ...mapActions({
      getMissions: 'mission/getMissions',
    }),

    ...mapMutations({
      clearMissions: 'mission/clearMissionList',
    }),

    async fetchMissions() {
      if (!this.isLoading) {
        this.isLoading = true
        await this.getMissions()
        this.isLoading = false
      }
    },
  },
}
</script>

<style scoped>
#add-mission-button {
  position: fixed;
  bottom: 64px;
  right: 64px;
}

.mission-title {
  cursor: pointer;
}

.mission-title:hover {
  color: yellow;
}

.company-desc {
  height: 45px;
  overflow: hidden;
}
</style>
